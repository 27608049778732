import React, { useEffect, useState } from 'react';
import './NavBarDesktop.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MaskGroupIcon from '../../../../assets/images_backup/Mask-group.svg';
import LogoIcon from '../../../../assets/images_backup/logo-gds.svg';

function NavBarDesktop() {
  const [selectedTab, setSelectedTab] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(pathname?.split('/')[1]);
  }, [pathname]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <div className="header-container">
        <div className="first-container">
          <div className="design-div">
            <img src={MaskGroupIcon} alt="Mask Icon" />
          </div>
          <div className="header-logo" onClick={() => navigate('/')}>
            <img src={LogoIcon} alt="Logo" />
          </div>
        </div>
        <div className="navigation-container">
          <Link
            to="/about-us"
            className={selectedTab === 'about-us' ? 'navigator selected-navigator' : 'navigator'}
          >
            About us
          </Link>
          <Link
            to="/current-project"
            className={selectedTab === 'current-project' ? 'navigator selected-navigator' : 'navigator'}
          >
            Current Project
          </Link>
          <Link
            to="/contact"
            className={selectedTab === 'contact' ? 'navigator selected-navigator' : 'navigator'}
          >
            Contact
          </Link>
          <Link
            to="/gallery"
            className={selectedTab === 'gallery' ? 'navigator selected-navigator' : 'navigator'}
          >
            Gallery
          </Link>
          <div className="navigator dropdown" onMouseLeave={closeDropdown}>
            <div
              className={
                selectedTab?.split('-')[0] === 'more'
                  ? 'navigator selected-navigator dropdown-trigger'
                  : 'navigator dropdown-trigger'
              }
              onClick={toggleDropdown}
            >
              More
            </div>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <Link
                  to="/more-ggm"
                  className="dropdown-item"
                  onClick={closeDropdown}
                >
                  Gold Global Market
                </Link>
                <Link
                  to="/more-gme"
                  className="dropdown-item"
                  onClick={closeDropdown}
                >
                  Gold Mining Exploration
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBarDesktop;