import React from 'react';
import "./AboutUsDesktop.css"
import { Link } from 'react-router-dom';
import AboutCarOneImage from "../../../../assets/images/about-car-img-one.jpg";
import AboutCarTwoImage from "../../../../assets/images/about-car-img-two.jpg";
import AboutCarThreeImage from "../../../../assets/images/about-car-img-three.jpg";
import OurMissionIconOne from "../../../../assets/images/our-mission-icon-one.jpg";
import OurMissionIconTwo from "../../../../assets/images/our-mission-icon-two.jpg";
import OurMissionIconThree from "../../../../assets/images/our-mission-icon-three.jpg";
import OurMissionIconFour from "../../../../assets/images/our-mission-icon-four.jpg";
import OurMissionIconFive from "../../../../assets/images/our-mission-icon-five.jpg";
import GoldMiningImage from "../../../../assets/images/gold-mining-image.jpg";
import FkbCardImgOne from "../../../../assets/images/fkb-card-img-one.jpg";
import FkbCardImgTwo from "../../../../assets/images/fkb-card-img-two.jpg";
import FkbCardImgThree from "../../../../assets/images/fkb-card-img-three.jpg";
import Kmu2024Image from "../../../../assets/images/kmu-2024.jpg";
import StarIcon from "../../../../assets/images_backup/star-icon.svg";
import AwardsIcon from "../../../../assets/images_backup/awards-icon.svg";
import MineralExplorationProcess from "../../../../assets/images/mineral-exploration-process.jpg";

function AboutUsDesktop() {
  return (
    <div className='parent-about-us-container'>
      <div className="margin-div"></div>
      <div className="about-carousel-container">
        <div className="carousel-content">
          <div className="bread-crumbs">
            <Link to="/" className="bread-crumbs-a">Home</Link> / About us
          </div>
          <div>
            <div className="about-title-one"><span>What we do </span><div className='ato-span'></div></div>
            <div className="about-title-two">About us</div>
            <div className="about-title-three">FOSTERING PROSPERITY, SUSTAINING GROWTH</div>
          </div>
        </div>
        <div className="car-sc-two">
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block w-100" src={AboutCarOneImage} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={AboutCarTwoImage} alt="Second slide" />
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={AboutCarThreeImage} alt="Third slide" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-story-container">
        <div className="osc-sub-container">
          <div className="os-text">Our story</div>
          <div className="os-text-two">
            <div>GDS Mining, founded in 2006, has evolved into a key player in resource extraction, particularly in mining since 2018. Guided by its core values of INSIGHT, INSPIRATION, and INTEGRITY, the company emphasizes innovation, ethical conduct, and strategic partnerships for long-term growth.</div>
            <div>With a focus on sustainability and leveraging technology, GDS Mining is well-positioned to lead in the mining industry while maintaining its reputation as a reliable and progressive partner across sectors.</div>
          </div>
        </div>
      </div>
      <div class="our-vision-container">
        <div class="our-vision-head">OUR VISION</div>
        <div class="ovc-sub-container">
          <div className='ovcsc-sub-card-container'>
            <div class="ocv-card">
              <div class="ovc-card-heading">GLOBAL LEADERSHIP</div>
              <div class="ovc-content">
                Aim to establish itself as a frontrunner in the mining industry by delivering high-quality,
                responsible solutions that meet the changing needs of the market.</div>
            </div>
            <div class="ocv-card">
              <div class="ovc-card-heading">EXPANSION AND DIVERSIFICATION</div>
              <div class="ovc-content">
                Pursue growth in emerging markets, particularly in Southeast Asia, while exploring new avenues in
                renewable energy, sustainable technologies, and advanced manufacturing.</div>
            </div>
            <div class="ocv-card">
              <div class="ovc-card-heading">TECHNOLOGICAL ADVANCEMENT</div>
              <div class="ovc-content">
                Embrace cutting-edge technologies like AI and automation to improve efficiency and reduce
                environmental impact.</div>
            </div></div>
          <div className='ovcsc-sub-card-container'>
            <div class="ocv-card">
              <div class="ovc-card-heading">SUSTAINABLE IMPACT</div>
              <div class="ovc-content">
                Set a high bar for sustainability and corporate responsibility by lowering the environmental
                footprint of operations and enhancing community benefits through job creation and educational
                initiatives.</div>
            </div>
            <div class="ocv-card">
              <div class="ovc-card-heading">LONG-TERM GROWTH AND PROSPERITY</div>
              <div class="ovc-content">
                Ensure GDS Mining remains stable and growing, capable of creating shared value for stakeholders
                through strategic planning and a commitment to long-term success.</div>
            </div>
            <div class="ocv-card"></div>
          </div>

        </div>
      </div>
      <div className="our-mission-container">
        <div className="our-mission-heading">OUR MISSION</div>
        <div className="om-container-title">GDS Mining drives sustainable, responsible mining across sectors like defense safety, agribusiness, and equipment supply. With expertise and innovative technology, we ensure efficient mineral extraction. Committed to excellence, environmental care, and partnerships, we deliver solutions with integrity, benefiting clients, stakeholders, and communities alike.</div>
        <div className="om-card-container">
          <div className='omc-card-sub-container'>
            <div className="om-card">
              <div className="omc-icon"><img src={OurMissionIconOne} alt="Sustainability Icon" /></div>
              <div className="omc-heading">Sustainability</div>
              <div className="omc-content">Prioritize eco-friendly practices in every mining and development phase to safeguard natural resources for future generations.</div>
            </div>
            <div className="om-card">
              <div className="omc-icon"><img src={OurMissionIconTwo} alt="Value Creation Icon" /></div>
              <div className="omc-heading">Value Creation</div>
              <div className="omc-content">Deliver long-term value to clients, shareholders, and communities by executing projects efficiently and ethically.</div>
            </div>
            <div className="om-card">
              <div className="omc-icon"><img src={OurMissionIconThree} alt="Innovation Icon" /></div>
              <div className="omc-heading">Innovation</div>
              <div className="omc-content">Stay ahead of the curve by continually adopting new technologies and processes, driving industry innovation and sustainability.</div>
            </div>
          </div>
          <div className='omc-card-sub-container'>
            <div className="om-card">
              <div className="omc-icon"><img src={OurMissionIconFour} alt="Safety and Compliance Icon" /></div>
              <div className="omc-heading">Safety and Compliance</div>
              <div className="omc-content">Maintain the highest safety standards and ensure strict adherence to global safety regulations for all employees and operations.</div>
            </div>
            <div className="om-card">
              <div className="omc-icon"><img src={OurMissionIconFive} alt="Community and Partnership Icon" /></div>
              <div className="omc-heading">Community and Partnership</div>
              <div className="omc-content">Build strong, collaborative relationships with local communities, governments, and partners to foster mutual growth and success.</div>
            </div>

             <div className="om-card">
             </div>
          </div>

        </div>
      </div>
      <div className="gold-mining-container">
        <div className="gold-mining-pic"><img src={GoldMiningImage} className="gm-img" alt="Gold Mining" /></div>
        <div className="gm-sub-container">
          <div className="gmc-head">Gold Mining</div>
          <div className="gmc-content">GDS Mining showcases a versatile business portfolio with a strong focus on excellence across several sectors, particularly in gold mining. The company specializes in both in-situ and alluvial extraction methods, allowing it to tap into diverse gold reserves. By employing modern and environmentally friendly techniques, GDS Mining enhances operational efficiency and output while minimizing its ecological impact.</div>
        </div>
      </div>
      <div className="fkb-container">
        <div className="fkb-main-heading">FOCUS KEY BUSINESSES</div>
        <div className="fkb-card-container">
          <div className="fkb-card">
            <div className="fkb-card-img"><img src={FkbCardImgOne} className="fkb-image" alt="Mining" /></div>
            <div className="fkb-card-content-container">
              <div className="fkb-card-heading">MINING</div>
              <div className="fkb-card-content">GDS Mining excels in gold extraction using both in-situ and alluvial methods, combining modern, eco-friendly techniques to boost efficiency and minimize environmental impact.</div>
            </div>
          </div>
          <div className="fkb-card">
            <div className="fkb-card-img"><img src={FkbCardImgTwo} className="fkb-image" alt="Agri Business" /></div>
            <div className="fkb-card-content-container">
              <div className="fkb-card-heading">AGRI BUSINESS</div>
              <div className="fkb-card-content">GDS Mining supports sustainable agriculture by distributing products, partnering with farmers, and promoting advanced technologies to enhance productivity and food security.</div>
            </div>
          </div>
          <div className="fkb-card">
            <div className="fkb-card-img"><img src={FkbCardImgThree} className="fkb-image" alt="Supply of Equipment and Machinery" /></div>
            <div className="fkb-card-content-container">
              <div className="fkb-card-heading">SUPPLY OF EQUIPMENT AND MACHINERY</div>
              <div className="fkb-card-content">GDS Mining supplies durable machinery for mining, construction, and agriculture, ensuring efficient operations with strong after-sales support for uninterrupted performance.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="milestone-containe">
        <p class="milestone-heading">OUR KEY MILESTONES</p>
        <div class="milestone-card-container">
          <div class="card-width">
            <p class="expertise-heading">Expertise</p>
            <p class="expertise-heading-1">18+</p>
            <p class="expertise-heading-2">Years of expertise</p>
          </div>
          <div class="card-width">
            <p class="expertise-heading">Growth</p>
            <p class="expertise-heading-1">45%</p>
            <p class="expertise-heading-2">In Last 2 Financial Year</p>
          </div>
          <div class="card-width">
            <p class="expertise-heading">Global Presence</p>
            <p class="expertise-heading-1">07</p>
            <p class="expertise-heading-2">Global Partnership</p>
          </div>
          <div class="card-width">
            <p class="expertise-heading">Employees</p>
            <p class="expertise-heading-1">70+</p>
            <p class="expertise-heading-2">Active Work Force</p>
          </div>
        </div>
      </div>
      <div className="kmu-container">
        <div className="kmu-img"><img src={Kmu2024Image} className="kmu-image" alt="KMU 2024" /></div>
      </div>

      <div class="core-assets-container">
        <div class="ca-content-container">
          <div class="ca-content-heading">Core Assets</div>
          <div class="ca-content">We emphasise partnership and collaboration among individuals to foster and sustain a
            cooperative and
            innovative business environment.</div>
        </div>
      </div>

      <div class="ana-main-container">
        <div class="star-container">
          <div><img src={StarIcon} alt='star'/></div>
          <div class="ana-main-heading">Awards and Achievements</div>
        </div>
        <div class="ana-main-sub-container">
          <div class="amsc-one">
            <div class="ana-card">
              <div class="ana-icon">
                <img src={AwardsIcon} class="ana-icon-image" alt='ana-icon'/>
              </div>
              <div class="ana-card-sub-container">
                <div class="ana-card-heading">OUTSTANDING ENTREPRENEURS AWARD 2021</div>
                <div class="ana-card-content">Recognized GDS Mining's innovation and leadership in expanding
                  operations across mining and agribusiness.
                </div>
              </div>
            </div>

            <div class="ana-card">
              <div class="ana-icon">
                <img src={AwardsIcon} class="ana-icon-image" alt='awards'/>
              </div>
              <div class="ana-card-sub-container">
                <div class="ana-card-heading">PREMIUM SPONSORSHIP AWARD, YOUTH UNION, LAO PDR, 2024</div>
                <div class="ana-card-content">Honoured GDS Mining’s support for youth initiatives, promoting
                  education and training.
                </div>
              </div>
            </div>

            <div class="ana-card">
              <div class="ana-icon">
                <img src={AwardsIcon} class="ana-icon-image" alt='award'/>
              </div>
              <div class="ana-card-sub-container">
                <div class="ana-card-heading">YOUNG ENTREPRENEURSHIP ASSOCIATION MEMBERSHIP, 2021</div>
                <div class="ana-card-content">Highlighted GDS Mining's role in fostering young entrepreneurs and
                  SMEs.
                </div>
              </div>
            </div>
          </div>
          <div class="amsc-two">
            <div class="ana-card">
              <div class="ana-icon">
                <img src={AwardsIcon} class="ana-icon-image" alt='award'/>
              </div>
              <div class="ana-card-sub-container">
                <div class="ana-card-heading">
                  CERTIFICATE OF APPRECIATION, LAO YOUTH UNION, 2023:</div>
                <div class="ana-card-content">Acknowledged GDS Mining’s contributions to youth education and
                  skill-building programs.
                </div>
              </div>
            </div>

            <div class="ana-card">
              <div class="ana-icon">
                <img src={AwardsIcon} class="ana-icon-image" alt='award'/>
              </div>
              <div class="ana-card-sub-container">
                <div class="ana-card-heading">CERTIFICATE OF APPRECIATION, DEPARTMENT OF ENERGY AND MINES, 2022
                </div>
                <div class="ana-card-content">Recognized responsible mining practices and contributions to
                  sustainable development.
                </div>
              </div>
            </div>

            <div class="ana-card">
              <div class="ana-icon">
                <img src={AwardsIcon} class="ana-icon-image" alt='award'/>
              </div>
              <div class="ana-card-sub-container">
                <div class="ana-card-heading">ADDITIONAL RECOGNITIONS</div>
                <div class="ana-card-content">GDS Mining has been widely appreciated for its CSR and
                  environmental
                  sustainability efforts.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mep-container">
        <div className="mep-img"><img src={MineralExplorationProcess} className="mep-image" alt="Mineral Exploration Process" /></div>
      </div>
    </div>
  );
}

export default AboutUsDesktop;
