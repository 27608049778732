import React from 'react'
import "./Pillars.css"

function Pillars() {
    return (
        <div className='pillar-primary-container'>
            <div className="pillers-head-contt">
                <p className="pillar-heading">Pillars of Success</p>
                <p className="pillar-sub-heading">Encompassing financial power, expertise, partnerships, and diverse industries.</p>
            </div>

            <div className="pillar-card-container">
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">Global Presence</p>
                    <p>Through 7 strategic global partnerships, we foster collaboration and achieve excellence.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">Professional Workforce</p>
                    <p>Over 70 skilled professionals drive our continued success and innovation.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">Net Worth</p>
                    <p>With a net worth exceeding $20 million, our financial strength is a testament to our leadership.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">Industry Expertise</p>
                    <p>With 18+ years of experience, we bring seasoned insight and expertise to every venture.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">Defence & Mining Solutions</p>
                    <p>Specialising in de-mining equipment, advanced tactical gear, and safety solutions for mining and related sectors.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">Real Estate Leadership</p>
                    <p>A key player in both commercial and residential real estate, shaping the future of urban development.</p>
                </div>
            </div>

        </div>
    )
}

export default Pillars
