import React from 'react'
import "./GoldMarket.css";
import Yellow from "../../../../src/assets/images_backup/yellowimg.svg"

function GoldMarket() {
    return (
        <div className='blue-div border-radius-3'>
            <div className='blue-first-container'>
                <p className='blue-heading'>Understanding the Global Gold Market</p>
                <p className='blue-small-text'>Gold, with the chemical symbol Au (from the Latin word Aurum) and an atomic number of 79, is a slightly orange-yellow, dense, soft, malleable, and ductile element. As a transition metal, gold is one of the least reactive chemical elements, which is why it is often found in its native state on Earth, as nuggets or grains in rocks, veins, and alluvial deposits. It occurs in solid solution with native silver and is naturally alloyed with other metals like copper and palladium, as well as mineral inclusions such as pyrite.</p>
            </div>
            <div>
                <p className='blue-second-heading'>Introduce the major participants in the gold market and explain their roles</p>

                <div className='d-flex justify-content-around '>
                    <div className='d-flex flex-column align-items-center text-center'>
                        <div style={{ width: "81px ", height: "81px" }} className='yellow-img'>
                            <img src={Yellow} alt="yellow" />

                        </div>
                        <p className='mining-company-text'>Mining Company</p>
                        <p className='yellow-subtext'>
                            Mining companies extract gold and play <br />a central role in determining supply.
                        </p>
                    </div>
                    <div className='d-flex flex-column text-center align-items-center'>
                        <div style={{ width: "81px ", height: "81px" }} className='yellow-img'>
                            <img src={Yellow} alt="yellow" />

                        </div>
                        <p className='mining-company-text'>Mining Company</p>
                        <p className='yellow-subtext'>
                            Mining companies extract gold and play <br />a central role in determining supply.
                        </p>
                    </div>
                    <div className='d-flex flex-column text-center align-items-center'>
                        <div style={{ width: "81px ", height: "81px" }} className='yellow-img'>
                            <img src={Yellow} alt="yellow" />

                        </div>
                        <p className='mining-company-text'>Mining Company</p>
                        <p className='yellow-subtext'>
                            Mining companies extract gold and play <br />a central role in determining supply.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GoldMarket
