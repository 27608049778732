import React from 'react'
import "./CurrentProjectMobile.css"
import GoldMining from "../../../../assets/images/gm-mobile-img.jpg";
import Location from "../../../../assets/images/gold-location.jpg";
import Accessibility from "../../../../assets/images/accessibility-mob-image.jpg";
import Physiography from "../../../../assets/images/physiography-mob-image.jpg";
import WeatherImg from "../../../../assets/images/wc-mob-cover-img.jpg";
import RGMob from "../../../../assets/images/rg-mob.jpg";
import DLFG from "../../../../assets/images/d-g.jpg"




function CurrentProjectMobile() {
  return (
    <div>
      <div className='cp-mobile'>
        CURRENT PROJECT
      </div>
      <div className='gm-mob-container'>
        <div className='gm-mob-image-container'><img src={GoldMining} alt='gm' className='gm-mob-image' /></div>
        <div className='gm-mob-text-container'>
          <div className='gm-mob-text-one'>GOLD MINING</div>
          <div className='gm-mob-text-two'>
            <div>GDS Mining is engaged in a range of ambitious projects that underscore its strategic growth and dedication to expanding its mining operations. These initiatives are vital to the company’s long-term vision of enhancing its mining portfolio while promoting sustainable resource development.</div>
            <div>Attapeu Mining Area, Southern Laos GDS Mining's largest project in Attapeu, Laos, covers 50 square kilometers for gold exploration. Strategically near Vietnam and Cambodia, it benefits from proximity to key infrastructure and transport routes, ensuring efficient operations. Nearby towns provide essential services and support for the workforce.</div>
          </div>
        </div>
      </div>

      <div className='gm-mob-container-new'>
        <div className='gm-mob-image-container-new'><img src={Location} alt='gm' className='gm-mob-image' /></div>
        <div className='gm-mob-text-container'>
          <div className='gm-mob-text-one'>LOCATION</div>
          <div className='gm-mob-text-two'>
            <div>The GDS block for gold prospecting is situated near the villages of Ban Phu-Kuea and Ban Phu-Yang in the Phouvung and Sanxay districts of Attapeu. Attapeu is in the southern part of Laos, bordering Vietnam and Cambodia.</div>
            <div>National Highway NH-11 and State Highway SH-18B run close to the northern boundary of the block. NH-11 connects Sekong to the Lao-Vietnam international border checkpoint at Ban Phu-Kuea, spanning approximately 200 km. Most essential infrastructure, including a police station, workshop facilities, hotels, and lodges, is available in Ban Phu-Kuea and at LAK-52, which is about 48 km NNW of the block. The nearest operational airport is in Pakse, Champasak province, located approximately 290 km to the northwest of the block.</div>
          </div>
        </div>
      </div>

      <div className='ap-mobile-container'>
        <div className='ap-mob-card-contt'>
          <div className='ap-card-m-image-container'><img src={Accessibility} alt='ap' className='apcm-image' /></div>
          <div className='ap-card-m-text-container'>
            <div className='apcmt-head'>Accessibility</div>
            <div className='apcmt-body'>The Lao PDR Government has approved the GDS Attapeu Gold prospecting block, which covers an area of 50 sq. km. Within this area, there are motorable and off-road paths, although they are not asphalted. Zone-1 is situated within Ban Phu-Kuea village, while Zone-2 is located in Ban Phu-Yang village. Currently, GDS Mining is primarily focused on Zone-1. To improve connectivity, GDS Mining has constructed a suitable road from Xayden to Zone-1. Existing roads connect Xayden to Ban Phu-Yang, Zone-1 to Ban Phu-Yang, and Ban Sombun to Ban Phu-Yang. The coordinates for all corner points of the block are detailed in the government-approved license document.</div>
          </div>
        </div>
        <div className='ap-mob-card-contt'>
          <div className='ap-card-m-image-container'><img src={Physiography} alt='ap' className='apcm-image' /></div>
          <div className='ap-card-m-text-container'>
            <div className='apcmt-head'>Physiography</div>
            <div className='apcmt-body'>The general surface level of the study area, as well as the surrounding region, slopes from east to west. The highest elevation reaches 1,000 meters above sea level in the east, while the lowest point is 300 meters above sea level in the west. Despite this incline, the area features undulating ridges and valleys. Within the block, the maximum elevation is 1,000 meters and the minimum is 500 meters. Most gold deposits are found at elevations between 600 and 800 meters. The northeastern part of the block exhibits a regional trend from northwest to southeast, while the southwestern side shows an east-west trend, suggesting the presence of a fault zone within the block.</div>
          </div>
        </div>
      </div>

      <div className='wc-mob-container'>
        <div className='wc-contt-mob-one'>WEATHER & CLIMATE</div>
        <div className='wc-contt-mob-two'><img src={WeatherImg} alt='wc' className='wcmt-img' /></div>
        <div className='wc-contt-mob-three'>In Attapeu, the wet season is characterized by oppressive and overcast conditions, while the dry season tends to be muggy and partly cloudy. Throughout the year, temperatures generally range from 65°F to 96°F, rarely dropping below 60°F or exceeding 102°F. The hot season lasts for about two months, from March to May, with average daily high temperatures above 94°F. April is the hottest month, averaging a high of 96°F and a low of 78°F. The cool season extends for two months, from November 27 to January 29, with average daily high temperatures below 87°F. January is the coldest month, with an average low of 66°F and a high of 86°F. The average cloud cover varies significantly throughout the year. The clearer part of the year begins in October and lasts for five months, ending in April. February is the clearest month, with the sky being clear, mostly clear, or partly cloudy 56% of the time. Conversely, the cloudier period starts in April and lasts for seven months, concluding in October, with August being the cloudiest month, during which the sky is overcast or mostly cloudy 95% of the time.</div>
        <div className='wc-contt-mob-three'>The wetter season in Attapeu lasts for six months, from May to November, with a greater than 27% likelihood of experiencing wet days. September has the highest number of wet days, averaging 14.3 days with at least 0.04 inches of precipitation. Conversely, the drier season spans six months, from mid-November to May, with February recording the fewest wet days, averaging just 1.1 days with at least 0.04 inches of rain. The rainy period lasts for 9.5 months, from March to January, during which there is a sliding 31-day total of at least 0.5 inches of rainfall. October is the wettest month, with an average rainfall of 6.4 inches. The dry period occurs for 2.5 months, from January to March, with February being the driest month, averaging only 0.3 inches of rain.</div>
      </div>

      <div className='rg-mob-container'>
        <div className='rgm-head'>REGIONAL GEOLOGY</div>
        <div className='rgm-img'><img src={RGMob} alt='rgm' className='rgmi' /></div>
        <div className='rgm-text-one'>The geological profile of Attapeu is based on data detailing stratigraphy, volcanic activity, and rock formations. Sedimentary rocks formed from the Cambrian to the Cretaceous, transitioning from marine to non-marine environments. Igneous activity began in the Precambrian with mafic rocks, followed by granitic intrusions in the Silurian-Permian, and intermediate to acidic rocks in the Triassic. Alkaline rocks appeared from the Cretaceous to the Tertiary.</div>
        <div className='rgm-text-two'>
          <div className='rgm-span'> 1. Silurian: I-type granite (island arc) </div>
          <div className='rgm-span'>2. Devonian: Transition from I-type granite (island arc) to A-type granite (island arc to within plate) </div>
          <div className='rgm-span'>3 .Carboniferous: S-type andesitic granite (island arc) </div>
          <div className='rgm-span'>4 .Permian: I-type andesitic granite (island arc)</div>
        </div>
      </div>
      <div className='dlfg-container-new'>
        <div className='dlfg-head'>DESIGNATED LAB FOR GEOCHEMISTRY</div>
        <div style={{width:"100%"}}><img src={DLFG} alt='dlfg' style={{borderRadius:"20px" , width:"100%"}}/></div>
        <div className='dlfg-text-container-new'>
          <div className='dlfg-text-one-new'>A Global Leader in Testing</div>
          <div className='dlfg-text-two-new'>For more than 40 years, ALS has provided comprehensive testing solutions to industries worldwide. With cutting-edge technology, innovative methods, and a team of global experts, we deliver top-tier services tailored to local needs and customized solutions.</div>
        </div>
      </div>
    </div>
  )
}

export default CurrentProjectMobile
