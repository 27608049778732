import React from 'react'
import "./HomeMobile.css";
import Arrow from "../../../../assets/images/btn-arrow.svg"
import OcvImg from "../../../../assets/images/mob-ocv.svg"
import CarImgOne from "../../../../assets/images/home-mob-carousel-one.jpg";
import CarImgTwo from "../../../../assets/images/home-mob-carousel-two.jpg";
import CarImgThree from "../../../../assets/images/home-mob-carousel-three-.jpg";
import { useNavigate } from 'react-router-dom';

function HomeMobile() {
  const navigate = useNavigate();
  return (
    <div>
      <div className='mob-car-container'>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src={CarImgOne} alt="First slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={CarImgTwo} alt="Second slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={CarImgThree} alt="Third slide" />
            </div>
          </div>
        </div>
        <div className='mob-carousel-content'>
          <div className='mcc-one'>FOSTERING PROSPERITY, SUSTAINING GROWTH</div>
          <div className='mcc-two'>From <span style={{ color: "#C48E08" }}>Powerhouse to Pioneer</span> Responsible Mining for a Sustainable Future</div>
          <div className='mcc-three'>GDS Mining Co., Ltd. mines gold, copper, and rare earth elements, focusing on innovation and sustainability.</div>
          <div className='mcc-four' onClick={() => navigate("/about-us")}>Read more <img src={Arrow} alt='...' /> </div>
        </div>
      </div>
      <div className='mob-pillars-of-success'>
        <div className='mpos-head'>PILLARS OF SUCCESS</div>
        <div className='mpos-text'>Encompassing financial power, expertise, partnerships, and diverse industries.</div>
        <div className='mpos-card-container'>
          <div className='mpos-card'>
            <div className='mpos-card-head'>GLOBAL PRESENCE</div>
            <div className='mpos-card-text'>Through 7 strategic global partnerships, we foster collaboration and achieve excellence.</div>
          </div>

          <div className='mpos-card'>
            <div className='mpos-card-head'>PROFESSIONAL WORKFORCE</div>
            <div className='mpos-card-text'>Over 70 skilled professionals drive our continued success and innovation.</div>
          </div>

          <div className='mpos-card'>
            <div className='mpos-card-head'>NET WORTH</div>
            <div className='mpos-card-text'>With a net worth exceeding $20 million, our financial strength is a testament to our leadership.</div>
          </div>

          <div className='mpos-card'>
            <div className='mpos-card-head'>INDUSTRY EXPERTISE</div>
            <div className='mpos-card-text'>With 18+ years of experience, we bring seasoned insight and expertise to every venture.</div>
          </div>

          <div className='mpos-card'>
            <div className='mpos-card-head'>DEFENCE & MINING SOLUTIONS</div>
            <div className='mpos-card-text'>Specialising in de-mining equipment, advanced tactical gear, and safety solutions for mining and related sectors.</div>
          </div>

          <div className='mpos-card'>
            <div className='mpos-card-head'>REAL ESTATE LEADERSHIP</div>
            <div className='mpos-card-text'>A key player in both commercial and residential real estate, shaping the future of urban development.</div>
          </div>
        </div>
      </div>
      <div className='mob-okm-container'>
        <div className='mob-okm-head'>OUR KEY MILESTONES</div>
        <div className='mob-okm-card-container'>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Experties</div>
            <div className='mokmc-text-one'>18+</div>
            <div className='mokmc-text-two'>Years of wxpertise</div>
          </div>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Growth</div>
            <div className='mokmc-text-one'>45%</div>
            <div className='mokmc-text-two'>In Last 2 Financial Year</div>
          </div>
        </div>
        <div className='mob-okm-card-container'>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Global Presence</div>
            <div className='mokmc-text-one'>07</div>
            <div className='mokmc-text-two'>Global partnership</div>
          </div>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Employees</div>
            <div className='mokmc-text-one'>70+</div>
            <div className='mokmc-text-two'>Active Work Force</div>
          </div>
        </div>
      </div>
      <div className='mob-mining-container'>
        <div className='mmc-sub-container'>
          <div className='mining-mob-head'>MINING</div>
          <div className='mob-mining-text'>We extract precious resources like gold, copper, and silver with care for the planet. Using the latest technology, we ensure responsible mining for a better future.</div>
          <div className='mmc-btn' onClick={() => navigate("/more-gme")}>Read more <img src={Arrow} alt='...' /></div>
        </div>
      </div>
      <div className='mob-cp-container'>
        <div className='mcp-head'>CURRENT PROJECT</div>
        <div className='mcp-text-one'>Attapeu, in Southern Laos, is a key mining region for GDS, known for its rich mineral resources and vital to our operations. In Ban Somboun, Phuvong District, we operate a significant alluvial gold mine, reflecting our commitment to diverse resource extraction.</div>
        <div className='mcp-btn' onClick={() => navigate("/current-project")}>See all projects <img src={Arrow} alt='...' /></div>
      </div>

      <div className='mob-ocv-container'>
        <div className='mcpc-head'>OUR CORE VALUE</div>
        <div><img src={OcvImg} alt="ocv" /></div>
        <div className='mcpc-card-container'>
          <div className='m-card-b'>
            <div className='mc-text-head'>INTEGRETY AND TRUST</div>
            <div className='mc-text'>We conduct our business with honesty and build trust through transparency in all our operations.</div>
          </div>
          <div className='m-card-y'>
            <div className='mc-text-head'>STABILITY AND LONGEVITY</div>
            <div className='mc-text'>With decades of experience, we ensure long-term stability and a steadfast commitment to our goals.</div>
          </div>
          <div className='m-card-b'>
            <div className='mc-text-head'>STRONG PARTNERSHIPS</div>
            <div className='mc-text'>We value and nurture long-term partnerships, focusing on mutual growth and collaboration.</div>
          </div>
          <div className='m-card-y'>
            <div className='mc-text-head'>PERSEVERANCE AND DETERMJINATION</div>
            <div className='mc-text'>We approach challenges with persistence and a strong, unwavering commitment to success</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeMobile
