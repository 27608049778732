import React from 'react';
import Instagram from "../../../../assets/images_backup/instagram-icon.svg";
import Facebook from "../../../../assets/images_backup/facebook-icon.svg";
import Youtube from "../../../../assets/images_backup/youtube-icon.svg";
import Twitter from "../../../../assets/images_backup/twitter-icon.svg";
import Linkedin from "../../../../assets/images_backup/linkedin-icon.svg";
import GDSLogo from "../../../../assets/images_backup/logo-gds.svg"
import LocationIcon from "../../../../assets/images_backup/location-icon.svg"
import PhoneIcon from "../../../../assets/images_backup/phone-icon.svg"
import { Link, useNavigate } from 'react-router-dom';
import "./FooterMobile.css";



function FooterMobile() {
  const navigate = useNavigate();
  return (
    <div className='mobile-footer-container'>
      <div className='mobile-gds-logo-container'><img src={GDSLogo} alt='logo' className='mob-gds-img' /></div>
      <div className='mobile-footer-text-one'>© GDS Mining 2004. All Rights Reserved</div>
      <div className='mobile-footer-text-two'>Innovating to deliver the essential materials the world relies on.</div>
      <div className='mob-footer-link-container'>
        <Link to="/about-us" className='mobile-footer-link'>About Us</Link>
        <Link to="/current-project" className='mobile-footer-link'>Current Projects</Link>
        <Link to="/contact" className='mobile-footer-link'>Contact</Link>
        <Link to="/gallery" className='mobile-footer-link'>Gallery</Link>
      </div>

      <div className='margin-div'></div>

      <div className="fsct-content" style={{marginTop:"25px"}}>Stay connected with GDS for updates</div>
      <div className="sm-container" style={{marginTop:"15px"}}>
        <div><img src={Instagram} alt="Instagram" /></div>
        <div><img src={Linkedin} alt="LinkedIn" /></div>
        <div><img src={Facebook} alt="Facebook" /></div>
        <div><img src={Twitter} alt="Twitter" /></div>
        <div><img src={Youtube} alt="YouTube" /></div>
      </div>

      <div className="header-sc" style={{marginTop:"25px"}}>
        <div className="header-sub-container" style={{ width: "100%" }}>
          <div>
            <img src={PhoneIcon} alt="Phone icon" />
          </div>
          <div>
            <div className="header-label">+856 20 57392 727</div>
            <div className="header-label">Gdsmining@gmail.com</div>
          </div>
        </div>

        <div className="header-sub-container" style={{ width: "100%" }}>
          <div>
            <img src={LocationIcon} alt="Location icon" />
          </div>
          <div>
            <div className="header-label">Ban Phonexai, District Saysettha</div>
            <div className="header-label">Vientiane, LAO PDR</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterMobile;
